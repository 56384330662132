import { pipe, prop } from 'ramda'
import { createAction } from 'redux-actions'

export const SET_SCREEN_WIDTH = 'screenWidth/SET_WIDTH'

const initialState = window.innerWidth

const screenWidth = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCREEN_WIDTH: {
      return window.innerWidth
    }
    default:
      return state
  }
}

export const setScreenWidth = createAction(SET_SCREEN_WIDTH)

const checkIsTablet = (screenWidth) => screenWidth < 960
const checkIsMobile = (screenWidth) => screenWidth < 600
const checkIsLaptop = (screenWidth) => screenWidth < 1025
const checkIsSmallScreen = (screenWidth) => screenWidth < 1195

const getScreenWidth = prop('screenWidth')
export const getIsTablet = pipe(getScreenWidth, checkIsTablet)
export const getIsMobile = pipe(getScreenWidth, checkIsMobile)
export const getIsLaptop = pipe(getScreenWidth, checkIsLaptop)
export const getIsSmallScreen = pipe(getScreenWidth, checkIsSmallScreen)

export default screenWidth
