export async function makeSafeRequest(fetcher) {
  try {
    const data = await fetcher()
    return {
      data,
    }
  } catch (error) {
    return {
      error,
    }
  }
}