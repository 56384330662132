export const JOIN = 'auth/JOIN'
export const JOIN_FAILED = 'auth/JOIN_FAILED'
export const JOIN_SUCCESS = 'auth/JOIN_SUCCESS'

export const SIGNIN = 'auth/SIGNIN'
export const SIGNIN_FAILED = 'auth/SIGNIN_FAILED'
export const SIGNIN_SUCCESS = 'auth/SIGNIN_SUCCESS'
export const VERIFICATION_CODE_RECEIVED = 'auth/VERIFICATION_CODE_RECEIVED'

export const SIGNUP = 'auth/SIGNUP'
export const SIGNUP_FAILED = 'auth/SIGNUP_FAILED'
export const SIGNUP_SUCCESS = 'auth/SIGNUP_SUCCESS'

export const SIGNOUT = 'auth/SIGNOUT'
export const SIGNOUT_FAILED = 'auth/SIGNOUT_FAILED'
export const SIGNOUT_SUCCESS = 'auth/SIGNOUT_SUCCESS'

export const RECOVER = 'auth/RECOVER'
export const RECOVER_FAILED = 'auth/RECOVER_FAILED'
export const RECOVER_SUCCESS = 'auth/RECOVER_SUCCESS'

export const RESET_PASSWORD = 'auth/RESET_PASSWORD'
export const RESET_PASSWORD_FAILED = 'auth/RESET_PASSWORD_FAILED'
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS'

export const ACTIVATE = 'auth/ACTIVATE'
export const ACTIVATE_FAILED = 'auth/ACTIVATE_FAILED'
export const ACTIVATE_SUCCESS = 'auth/ACTIVATE_SUCCESS'

export const START_SOCIAL_AUTH = 'auth/social/START_SOCIAL_AUTH'
export const AUTH_BY_TOKEN = 'auth/AUTH_BY_TOKEN'

export const CARD_ID_PLUGIN_FAILED = 'auth/CARD_ID_PLUGIN_FAILED'

export const SET_AUTH = 'auth/SET_AUTH'
export const LOGOUT = 'auth/LOGOUT'
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS'
