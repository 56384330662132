import { createSelector } from 'reselect'
import { prop, compose, path, find, endsWith, flip, isEmpty } from 'ramda'

const getStaticContent = prop('salesStatic')

const urlEndsWithSlug = (url) => compose(flip(endsWith)(url), prop('link'))
const getTitle = prop('subject')

const linksSelector = createSelector(getStaticContent, path(['links', 'items']))
export const getVCCFTypes = createSelector(getStaticContent, path(['vccf', 'types']))
export const getVCCFAddresses = createSelector(getStaticContent, path(['vccf', 'addresses']))
export const menuItemsSelector = createSelector(getStaticContent, path(['menuItems', 'menuItems']))
export const areLinksEmpty = createSelector(getStaticContent, compose(isEmpty, path(['links', 'items'])))

export const getPageTitleBySlug = (url) => compose(getTitle, find(urlEndsWithSlug(url)), menuItemsSelector)

export const getLinkTitleBySlug = (url) => compose(getTitle, find(urlEndsWithSlug(url)), linksSelector)

export const getUserGuideData = createSelector(getStaticContent, path(['userGuide']))
