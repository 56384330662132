import { FORM_MODE_COMPANY } from '../modules/Booking/forms/CustomerInfo/consts'
import { correctSymbolsRegExp, emailRegExp } from './validate'

export const areContactFormValuesValid = (contactValues = {}) => {
  const isPhoneCodeValid = /^[0-9]+$/.test(contactValues.phoneCode)
  // const isOwnerEmailValid = /^([a-z0-9_.-]+)@([a-z0-9_.-]+)\.([a-z]+)$/i.test(contactValues.email)
  const isOwnerEmailValid = emailRegExp.test(contactValues.email)
  const isCountryValid = /^[0-9]+$/.test(contactValues.country)
  const isFirstNameValid = correctSymbolsRegExp.test(contactValues.firstName)
  const isLastNameValid = correctSymbolsRegExp.test(contactValues.lastName)

  const result = Boolean(
    isOwnerEmailValid &&
      isPhoneCodeValid &&
      contactValues.phoneNumber &&
      contactValues.firstName &&
      isFirstNameValid &&
      contactValues.lastName &&
      isLastNameValid &&
      (contactValues.formMode !== FORM_MODE_COMPANY ||
        (isCountryValid &&
          contactValues.legalName &&
          contactValues.registrationNumber &&
          contactValues.addressLine &&
          contactValues.zip &&
          contactValues.city))
  )

  return result
}
