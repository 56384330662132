import axios from 'axios'
import { prop, compose, pathOr, not, flip, contains } from 'ramda'
import { captureException } from '@sentry/browser'

import { FETCHING_START, FETCHING_COMPLETE, SET_NETWORK_ERROR, CLEAR_NETWORK_ERROR } from '../../consts/actions'
import { getToken as tokenSelector } from '../../services/auth/reducers/selectors'
import { signout } from '../../services/auth/actions'

import getFakeData from './fakeData'
import { VEHICLE_INPUT_TYPE, TRAILER_INPUT_TYPE } from '../../services/user-selections/consts'

const getLocaleProp = prop('locale')

const { requestTrackParam, requestSpanParam } = window.brandProps

export function axiosConfigure(store) {
  const { dispatch, getState } = store
  const getLocale = compose(getLocaleProp, getState)
  const getToken = compose(tokenSelector, getState)

  axios.defaults.timeout = 40000
  axios.defaults.withCredentials = true
  axios.defaults.headers['Accept-Language'] = getLocale()

  axios.interceptors.request.use((config) => {
    const { baseURL = '', url = '' } = config
    if (baseURL.includes('staging.maqta.ae') || url.includes('staging.maqta.ae')) {
      return config
    }

    if (process.env.NODE_ENV !== 'production') {
      dispatch({
        type: FETCHING_START,
        payload: config.url,
      })
    }

    const headers = {
      ...config.headers,
      common: {
        ...config.headers.common,
        'Accept-Language': getLocale() || 'en',
        locale: getLocale() || 'en', // https://trello.com/c/iNUZJvUj
        ...(getToken() && { 'Cust-Authorization': `Bearer ${getToken()}` }),
        [requestTrackParam]: Date.now(),
        [requestSpanParam]: Date.now(),
      },
    }

    return {
      ...config,
      headers,
    }
  })

  axios.interceptors.response.use(
    (res) => {
      const url = pathOr('', ['config', 'url'], res)

      if (process.env.NODE_ENV !== 'production') {
        dispatch({
          type: FETCHING_COMPLETE,
          payload: url,
        })
      }

      dispatch({
        type: CLEAR_NETWORK_ERROR,
      })

      return res
    },
    (err) => {
      const url = pathOr('', ['config', 'url'], err)
      const data = pathOr({ message: 'General Error' }, ['response', 'data'], err)

      if (data.status && data.status === 401) dispatch(signout())

      try {
        const { data: errorData = {} } = data
        dispatch({
          type: SET_NETWORK_ERROR,
          payload: errorData.code || 'Default Error',
        })
      } catch (e) {
        console.error(e)
      }

      if (process.env.NODE_ENV !== 'production') {
        dispatch({
          type: FETCHING_COMPLETE,
          payload: url,
        })
      }

      if (err.response) {
        const { data, status, config } = err.response
        const requestHeaders = prop('headers')(config)

        const url = pathOr('', ['request', 'responseURL'])(err)
        if (status >= 400 && status !== 400 && !url.includes('/erd?'))
          captureException({ url, data, status, requestHeaders })
      }

      return Promise.reject(data)
    }
  )

  axios.interceptors.response.use(prop('data'))

  return (next) => (action) => {
    // this is no place for the middleware, can be moved later
    const passTypes = ['/main-inside/select-tickets', '/main-inside/confirm']

    const check = compose(not, flip(contains)(passTypes))
    if (action.type === 'navigate/GO_TO_PAGE' && check(action.payload)) {
      dispatch({
        type: VEHICLE_INPUT_TYPE,
        payload: 'simple',
      })
      dispatch({
        type: TRAILER_INPUT_TYPE,
        payload: 'simple',
      })
    }
    if (action.type === 'ticket-selections:CHANGE_ITEM_QTTY' && action.payload.type === 'confirm') {
      dispatch({
        type: CLEAR_NETWORK_ERROR,
      })
    }

    if (window.location.href.includes('?fake')) {
      if (pathOr('', ['meta', 'fake'], action)) {
        const {
          payload,
          meta: {
            fake: { success, name },
          },
        } = action

        dispatch({
          type: success,
          ...getFakeData(name, payload, { dispatch, getState }),
        })

        return
      }
    }

    next(action)
  }
}
