import axios from 'axios'

export function getScheduleBySlug(locale, slug) {
  return axios.get('/api/sales-static/schedule', {
    params: { slug, locale },
  })
}

export function getScheduleRoot(locale) {
  return axios.get('/api/sales-static/all-schedules', {
    params: { locale },
  })
}
