import { createAction } from 'redux-actions'
import * as actions from '../consts/schedule'

const datesFake = () => ({
  fake: {
    name: 'dates',
    success: actions.FETCH_SCHEDULE_AVAILABLE_DATES_SUCCESS,
  },
})

const sailsFake = () => ({
  fake: {
    name: 'sails',
    success: actions.SW_SAILS_FETCH_SUCCESS,
  },
})

export const clearSchedule = createAction(actions.CLEAR_SCHEDULE)
export const fetchAvailableReplacementLegs = createAction(actions.FETCH_AVAILABLE_REPLACEMENT_LEGS)
export const fetchAvailableDates = createAction(actions.FETCH_SAILS_SCHEDULE, null, datesFake)
export const fetchSailsForLegAndDate = createAction(actions.FETCH_SAILS_FOR_LEG_AND_DATE, null, sailsFake)
export const clearSailSelection = createAction(actions.CLEAR_SCHEDULE_SELECTED_SAILS)
export const setSelectedSail = createAction(actions.SELECT_SCHEDULE_SAIL)
export const setSelectedSailForEdit = createAction(actions.SELECT_EDIT_SCHEDULE_SAIL)
export const clearEditList = createAction(actions.CLEAR_EDIT_SCHEDULE_SAIL_LIST)
export const manageSchedulePartsFetchResponse = createAction(actions.MANAGE_SCHEDULE_PARTS_FETCH_RESPONSE)
export const omitPackageFromScheduleState = createAction(actions.OMIT_PACKAGE_FROM_SCHEDULE_STATE)
export const clearSWTimeouts = createAction(actions.SW_CLEAR_TIMEOUTS)

export const setScheduleState = createAction(actions.SET_SCHEDULE_STATE)
export const removeSailRefByCode = createAction(actions.REMOVE_SAIL_REF_BY_CODE)
export const setTimestamp = createAction(actions.SET_SELECTED_TIMESTAMP)
export const clearTimestamps = createAction(actions.CLEAR_TIMESTAMPS)
