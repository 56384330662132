import { map, mergeAll, pipe, propOr } from 'ramda'

export const normalizePrices = (pricesFromState) => {
  // eslint-disable-next-line prefer-destructuring
  const { carDeckPrices, prices } = Object.values(pricesFromState)[0]
  const pricesObject = mergeAll([carDeckPrices, prices])
  const normalizePriceEntry = (priceEntry) => {
    if (priceEntry[0] === 'CHARTER_PAX') {
      return [priceEntry[0], propOr({}, 'displayPrice')(priceEntry[1])]
    }
    return [priceEntry[0], propOr({}, 'details')(priceEntry[1])]
  }
  const normalizedPrices = pipe(map(normalizePriceEntry), Object.fromEntries)(Object.entries(pricesObject))
  return normalizedPrices
}

export const calculateTotalPriceWithoutTaxes = (tickets, prices) => {
  return !tickets.length
    ? 0
    : tickets.reduce((total, ticket) => {
        // eslint-disable-next-line no-param-reassign
        total += prices[ticket.code].amountWithoutTaxes * ticket.count
        return total
      }, 0)
}
