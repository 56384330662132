import { compose, curry, map, path, pathOr, pick, prop, tail } from 'ramda'
import { getLocale } from '../user-selections'

const cmsBase = '/cms-images'

export const projectCustomFields = (optionalFields, response) => ({
  ...(optionalFields && response ? pick(optionalFields, response) : {}),
  ...(response ? response.acf : {}),
})

export const projectTotalPagesAndData = (optionalFields, response) => {
  const data = pathOr([], ['data'], response)
  return {
    items: response ? map(curry(projectCustomFields)(optionalFields))(data) : [],
  }
}

export const mapStateToPropsForStaticPages = (state) => {
  return {
    locale: getLocale()(state),
    pageTitle: '',
    links: state.salesStatic.links.items,
    ...state.salesStatic.links,
  }
}

export const smallImagesPath = path(['images', 0, 'formats', 'small', 'url'])
export const smallImagePath = path(['image', 'formats', 'small', 'url'])
export const mediumImagesPath = path(['images', 0, 'formats', 'medium', 'url'])
export const mediumImagesTail = compose(tail, map(path(['formats', 'medium', 'url'])), prop('images'))

export const getOneSmallImageSrc = (item) => {
  if (smallImagePath(item)) return `${cmsBase}${smallImagePath(item)}`
  return null
}

export const getImageLink = (image) => (image && image.url ? `${cmsBase}${image.url}` : '')
export const getImageLinks = (images) => images.map(getImageLink)
