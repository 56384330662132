export const ADD_GROUP = 'SERVICE:PASSENGERS_DATA:ADD_GROUP'
export const ADD_GROUP_FAIL = 'SERVICE:PASSENGERS_DATA:ADD_GROUP_FAIL'
export const ADD_GROUP_SUCCESS = 'SERVICE:PASSENGERS_DATA:ADD_GROUP_SUCCESS'

export const MODIFY_GROUP = 'SERVICE:PASSENGERS_DATA:MODIFY_GROUP'
export const MODIFY_GROUP_FAIL = 'SERVICE:PASSENGERS_DATA:MODIFY_GROUP_FAIL'
export const MODIFY_GROUP_SUCCESS = 'SERVICE:PASSENGERS_DATA:MODIFY_GROUP_SUCCESS'

export const ADD_MEMBER = 'SERVICE:PASSENGERS_DATA:ADD_MEMBER'
export const ADD_MEMBER_FAIL = 'SERVICE:PASSENGERS_DATA:ADD_MEMBER_FAIL'
export const ADD_MEMBER_SUCCESS = 'SERVICE:PASSENGERS_DATA:ADD_MEMBER_SUCCESS'

export const MODIFY_MEMBER = 'SERVICE:PASSENGERS_DATA:MODIFY_MEMBER'
export const MODIFY_MEMBER_FAIL = 'SERVICE:PASSENGERS_DATA:MODIFY_MEMBER_FAIL'
export const MODIFY_MEMBER_SUCCESS = 'SERVICE:PASSENGERS_DATA:MODIFY_MEMBER_SUCCESS'

export const GET_GROUPS_WITH_THEIR_MEMBERS = 'SERVICE:PASSENGERS_DATA:GET_GROUPS_WITH_THEIR_MEMBERS'
export const GET_GROUPS_WITH_THEIR_MEMBERS_FAIL = 'SERVICE:PASSENGERS_DATA:GET_GROUPS_WITH_THEIR_MEMBERS_FAIL'
export const GET_GROUPS_WITH_THEIR_MEMBERS_SUCCESS = 'SERVICE:PASSENGERS_DATA:GET_GROUPS_WITH_THEIR_MEMBERS_SUCCESS'

export const GET_ALL_MEMBERS = 'SERVICE:PASSENGERS_DATA:GET_ALL_MEMBERS'
export const GET_ALL_MEMBERS_FAIL = 'SERVICE:PASSENGERS_DATA:GET_ALL_MEMBERS_FAIL'
export const GET_ALL_MEMBERS_SUCCESS = 'SERVICE:PASSENGERS_DATA:GET_ALL_MEMBERS_SUCCESS'

export const DELETE_GROUP = 'SERVICE:PASSENGERS_DATA:DELETE_GROUP'
export const DELETE_GROUP_FAIL = 'SERVICE:PASSENGERS_DATA:DELETE_GROUP_FAIL'
export const DELETE_GROUP_SUCCESS = 'SERVICE:PASSENGERS_DATA:DELETE_GROUP_SUCCESS'

export const DELETE_MEMBER = 'SERVICE:PASSENGERS_DATA:DELETE_MEMBER'
export const DELETE_MEMBER_FAIL = 'SERVICE:PASSENGERS_DATA:DELETE_MEMBER_FAIL'
export const DELETE_MEMBER_SUCCESS = 'SERVICE:PASSENGERS_DATA:DELETE_MEMBER_SUCCESS'

export const GET_GROUP_TICKETS = 'SERVICE:PASSENGERS_DATA:GET_GROUP_TICKETS'
export const GET_GROUP_TICKETS_FAIL = 'SERVICE:PASSENGERS_DATA:GET_GROUP_TICKETS_FAIL'
export const GET_GROUP_TICKETS_SUCCESS = 'SERVICE:PASSENGERS_DATA:GET_GROUP_TICKETS_SUCCESS'
export const GET_GROUP_TICKETS_START = 'SERVICE:PASSENGERS_DATA:GET_GROUP_TICKETS_START'

export const ADD_SAVED_GUEST = 'SERVICE:PASSENGERS_DATA:ADD_SAVED_GUEST_SEQ_N'

export const RESET_GROUP_TICKETS = 'SERVICE:PASSENGERS_DATA:RESET_GROUP_TICKETS'
