import { getFormNames, initialize, reset } from 'redux-form'
import { compose, join, path, split, update } from 'ramda'
import { put, select, takeEvery, takeLatest, throttle } from 'redux-saga/effects'

import {
  addReturnRoute,
  backToPreviousPage,
  goToEditReservation,
  goToPage,
  goToRefund,
  setViewMode,
  showMessage,
  showModal,
  switchViewModeAction,
} from '../../actions'

import { auth, customer as customerService, reservation, selections, user as userService } from '../../services'

import AccessToHistory from '../../modules/Common/AccessToHistory'

const { getLocale, changeLocale, resetAllForms, selectReverseSailPackage } = selections

const { signinSuccess, signupSuccess, signoutSuccess, startSocialAuth, authByToken, setAuth, logoutSuccess } =
  auth.actions

const {
  fetchUserDetails,
  inviteUserToCompanySuccess,
  removeUserFromCompanySuccess,
  resetUser,
  setUserDetails,
  upsertUserCompanySuccess,
} = userService.actions

const { fetchUserCustomerInfo } = customerService.actions

const { setRefundReservation, setReservation, watchCancelledReservation } = reservation.actions.reservation

const moveViaHistory = (payload, locale) => {
  const accessToHistory = new AccessToHistory()
  if (accessToHistory.history) {
    accessToHistory.prevPath = accessToHistory.history.location.pathname
    accessToHistory.history.push(`/${locale}${payload}`)
  }
}

const replaceUrlLocale = (newLocale) => {
  const accessToHistory = new AccessToHistory()
  if (accessToHistory.history) {
    const pathname = path(['location', 'pathname'], accessToHistory.history)
    const newPath = compose(join('/'), update(1, newLocale), split('/'))(pathname)
    accessToHistory.history.replace(newPath)
  }
}

const back = () => {
  const accessToHistory = new AccessToHistory()
  if (accessToHistory.history) {
    if (
      accessToHistory.history.action === 'PUSH' &&
      accessToHistory.prevPath &&
      !accessToHistory.prevPath.includes('recovery')
    ) {
      accessToHistory.history.goBack()
    } else {
      accessToHistory.history.push('/')
    }
  }
}

// eslint-disable-next-line
function* socialAuthManage({ payload: { social } }) {
  const locale = yield select(getLocale())
  window.location.replace(`/auth/${social}?locale=${locale}`)
}

function* signinSuccessHandler({ payload: { user, joinToken } }) {
  yield put(setUserDetails(user))
  yield put(fetchUserCustomerInfo())

  if (joinToken) {
    yield put(goToPage('/corporate/new'))
  } else {
    yield put(goToPage('/'))
  }
}

function* signupSuccessHandler() {
  // yield put(goToPage('/'))
  yield put(showMessage('signup'))
}

function* signoutSuccessHandler() {
  yield put(goToPage('/'))
  yield put(reset('contact'))
  yield put(resetUser())
}

function* logoutSuccessHandler() {
  yield put(reset('contact'))
  yield put(resetUser())
}

function* addCompanySuccessHandler() {
  yield put(goToPage('/profile/settings'))
}

function* goToUsers() {
  yield put(goToPage('/profile/users'))
}

function* goToUsersAndCloseWindow() {
  yield put(showModal(''))
  yield put(goToPage('/profile/users'))
}

function* resetAllExistingForms() {
  const formNames = yield select(getFormNames())
  // eslint-disable-next-line no-restricted-syntax
  for (const value of formNames) {
    yield put(initialize(value, {}))
  }
}

function* goToDirection({ payload }) {
  const locale = yield select(getLocale())
  moveViaHistory(payload, locale)
}

function* changeUrlLocale({ payload }) {
  const previousLocale = yield select(getLocale())
  replaceUrlLocale(previousLocale, payload)
}

function* clearAuthMessages() {
  yield put(setAuth({ error: '', message: '' }))
}

function* switchViewMode({ payload }) {
  const { redirect = false, isCancelled = false, reservation = false } = payload
  if (isCancelled) {
    yield put(setViewMode('cancelled'))
    yield put(watchCancelledReservation(reservation))
  }
  if (redirect) {
    yield put(goToPage(redirect))
  }
}

function* addReturnRouteHandler() {
  yield put(selectReverseSailPackage(true))
}

function* authUserViaSocial({ payload }) {
  yield put(setAuth({ token: payload, isFetching: false }))
  yield put(fetchUserDetails())
  yield put(goToPage('/'))
}

function* goToReservationRefund({ payload: reservation }) {
  yield put(setRefundReservation(reservation))
  yield put(goToPage('/refund'))
}

function* goToReservationEdit({ payload: { reservation, mode } }) {
  yield put(
    setReservation({
      type: 'edit',
      reservation: {
        reservationId: reservation.reservationId,
        token: reservation.token,
        reservation,
        mode,
        editedReservation: reservation,
        editedParams: {},
      },
    })
  )
  yield put(goToPage('/myticket-edit'))
}

export function* watchSuccessSignin() {
  yield takeEvery(signinSuccess, signinSuccessHandler)
}

export function* watchSuccessSignup() {
  yield takeEvery(signupSuccess, signupSuccessHandler)
}

export function* watchSuccessSignout() {
  yield takeEvery(signoutSuccess, signoutSuccessHandler)
}

export function* watchSuccessLogout() {
  yield takeEvery(logoutSuccess, logoutSuccessHandler)
}

export function* watchAddCompany() {
  yield takeEvery(upsertUserCompanySuccess, addCompanySuccessHandler)
}

export function* watchResetAllForms() {
  yield takeEvery(resetAllForms, resetAllExistingForms)
}

export function* watchGoToPage() {
  yield takeLatest(goToPage, goToDirection)
}

export function* watchBackToPreviousPage() {
  yield takeLatest(backToPreviousPage, back)
}

export function* watchChangeLocale() {
  yield throttle(500, changeLocale, changeUrlLocale)
}

export function* watchChangeLocaleToClearError() {
  yield takeEvery(changeLocale, clearAuthMessages)
}

export function* watchSocialAuthStarting() {
  yield takeEvery(startSocialAuth, socialAuthManage)
}

export function* watchViewMode() {
  yield takeLatest(switchViewModeAction, switchViewMode)
}

function* watchAddReturnRoute() {
  yield takeEvery(addReturnRoute, addReturnRouteHandler)
}

function* watchAuthByToken() {
  yield takeEvery(authByToken, authUserViaSocial)
}

function* watchGoToRefund() {
  yield takeEvery(goToRefund, goToReservationRefund)
}

function* watchGoToEditReservation() {
  yield takeEvery(goToEditReservation, goToReservationEdit)
}

function* watchSuccessInvitation() {
  yield takeEvery(inviteUserToCompanySuccess, goToUsers)
}

function* watchSuccessRemoveUserFromCompany() {
  yield takeEvery(removeUserFromCompanySuccess, goToUsersAndCloseWindow)
}

export default function* app() {
  yield [
    watchGoToPage(),
    watchBackToPreviousPage(),
    watchSocialAuthStarting(),
    watchChangeLocale(),
    watchSuccessSignin(),
    watchSuccessSignup(),
    watchResetAllForms(),
    watchSuccessSignout(),
    watchSuccessLogout(),
    watchAddCompany(),
    watchViewMode(),
    watchAddReturnRoute(),
    watchAuthByToken(),
    watchGoToRefund(),
    watchGoToEditReservation(),
    watchSuccessInvitation(),
    watchSuccessRemoveUserFromCompany(),
    watchChangeLocaleToClearError(),
  ]
}
