import { createAction } from 'redux-actions'
import * as actions from '../consts'

export const addGroup = createAction(actions.ADD_GROUP)
export const addGroupFail = createAction(actions.ADD_GROUP_FAIL)
export const addGroupSuccess = createAction(actions.ADD_GROUP_SUCCESS)

export const modifyGroup = createAction(actions.MODIFY_GROUP)
export const modifyGroupFail = createAction(actions.MODIFY_GROUP_FAIL)
export const modifyGroupSuccess = createAction(actions.MODIFY_GROUP_SUCCESS)

export const addMember = createAction(actions.ADD_MEMBER)
export const addMemberFail = createAction(actions.ADD_MEMBER_FAIL)
export const addMemberSuccess = createAction(actions.ADD_MEMBER_SUCCESS)

export const modifyMember = createAction(actions.MODIFY_MEMBER)
export const modifyMemberFail = createAction(actions.MODIFY_MEMBER_FAIL)
export const modifyMemberSuccess = createAction(actions.MODIFY_MEMBER_SUCCESS)

export const getGropsWithTheirMembers = createAction(actions.GET_GROUPS_WITH_THEIR_MEMBERS)
export const getGropsWithTheirMembersFail = createAction(actions.GET_GROUPS_WITH_THEIR_MEMBERS_FAIL)
export const getGropsWithTheirMembersSuccess = createAction(actions.GET_GROUPS_WITH_THEIR_MEMBERS_SUCCESS)

export const getAllMembers = createAction(actions.GET_ALL_MEMBERS)
export const getAllMembersFail = createAction(actions.GET_ALL_MEMBERS_FAIL)
export const getAllMembersSuccess = createAction(actions.GET_ALL_MEMBERS_SUCCESS)

export const deleteGroup = createAction(actions.DELETE_GROUP)
export const deleteGroupFail = createAction(actions.DELETE_GROUP_FAIL)
export const deleteGroupSuccess = createAction(actions.DELETE_GROUP_SUCCESS)

export const deleteMember = createAction(actions.DELETE_MEMBER)
export const deleteMemberFail = createAction(actions.DELETE_MEMBER_FAIL)
export const deleteMemberSuccess = createAction(actions.DELETE_MEMBER_SUCCESS)

export const getGroupTickets = createAction(actions.GET_GROUP_TICKETS)
export const getGroupTicketsFail = createAction(actions.GET_GROUP_TICKETS_FAIL)
export const getGroupTicketsSuccess = createAction(actions.GET_GROUP_TICKETS_SUCCESS)

export const addSavedGuest = createAction(actions.ADD_SAVED_GUEST)

export const resetGroupTickets = createAction(actions.RESET_GROUP_TICKETS)
