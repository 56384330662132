import { propOr } from 'ramda'
import { IDGenerator } from '../../utils/idGenerator'

const generator = new IDGenerator()

export const mergeVehicleData = (arrayOfDefinedSubtypeItems = [], vehiclesFromReservation = []) =>
  arrayOfDefinedSubtypeItems.map((item) => {
    const [seqN] = item.ownerSeqNs
    const { licencePlate } =
      vehiclesFromReservation.length > 0 ? vehiclesFromReservation.find((vehicle) => vehicle.seqN === seqN) : ''
    return {
      ...item,
      licencePlate,
    }
  })

export const areLicensePlatesForRoundTripDifferent = (arrayOfVehicleSubtype = [], arrayOfTrailerSubtype = []) => {
  if (arrayOfVehicleSubtype && arrayOfVehicleSubtype.length > 1) {
    const [vehicleForward, vehicleBack] = arrayOfVehicleSubtype
    if (vehicleForward.licencePlate !== vehicleBack.licencePlate) {
      return true
    }
  }
  if (arrayOfTrailerSubtype && arrayOfTrailerSubtype.length > 1) {
    const [trailerForward, trailerBack] = arrayOfTrailerSubtype
    if (trailerForward.licencePlate !== trailerBack.licencePlate) {
      return true
    }
  }
  return false
}

export const makeTicketsRequest = ({ members, selectedSailPackages, selectedSailRefIds }) => {
  const ticketsRequest = members.reduce((acc, { customerId, priceCategory = 'PAX001' }) => {
    const seqN = generator.generate()
    selectedSailPackages.forEach(({ code }) => {
      const ticketItem = {
        sailPackage: code,
        sailRefIds: [selectedSailRefIds[code]],
        seqN,
        passenger: {
          customerId,
          priceCategory,
        },
      }
      acc.push(ticketItem)
    })
    return acc
  }, [])
  return ticketsRequest
}

export const getQuantitiesOfSelectedPriceCategories = (items = []) => {
  const quantitiesOfSelectedPriceCategories = {}
  items.forEach(({ priceCategory, quantity }) => {
    quantitiesOfSelectedPriceCategories[priceCategory] = quantity
  })
  return quantitiesOfSelectedPriceCategories
}

export const filterFeeItems = (items) => items.filter(({ inventoryClass }) => inventoryClass !== 'FEE')

export const removeFeeItemsFromReservation = (reservation = {}) => {
  const items = propOr([], 'items')(reservation)
  const itemsWithoutFee = filterFeeItems(items)
  return {
    ...reservation,
    items: itemsWithoutFee,
  }
}
