import { createSelector } from 'reselect'
import { prop, values, map, pick } from 'ramda'

import sailPackagesReducer from './sailPackages'

export default sailPackagesReducer

const getSailPackagesState = prop('sailPackages')
const getSailPackages = (state) => getSailPackagesState(state) || {}
const getImageUrl = (leg) => (leg ? `/images/${leg.portFrom}.png` : '')

export const getAllSailPackages = getSailPackagesState

export const getAllSailPackagesList = createSelector(getSailPackagesState, values)

const titlesAndCodesAndTypeOnly = map(pick(['title', 'code', 'type', 'titles']))
export const getSailPackagesDepartures = createSelector(getAllSailPackagesList, (packagesList) =>
  titlesAndCodesAndTypeOnly(packagesList)
)

export const sailPackages = createSelector(getSailPackages, (sailPackagesList) =>
  Object.keys(sailPackagesList).map((key) => {
    const sailPackage = sailPackagesList[key]

    return {
      ...sailPackage,
      imgSource: getImageUrl(sailPackage.route && sailPackage.route.legs && sailPackage.route.legs[0]),
    }
  })
)

export const getSailPackageByCode = (state, packageCode) => getSailPackages(state)[packageCode] || {}

export const getSailPackageLeg = createSelector(
  (state, packageCode, legCode) => ({
    sailPackage: getSailPackageByCode(state, packageCode),
    legCode,
  }),
  ({ sailPackage, legCode }) => {
    const sailPackageLegs = (sailPackage.route && sailPackage.route.legs) || []
    return sailPackageLegs.filter((leg) => leg.code === legCode)[0] || {}
  }
)

export const getSailPackagesRoutes = (state) =>
  Object.values(getSailPackagesState(state)).map((sailPackage) => sailPackage.route)
