import moment from 'moment'

const checkIsSailTimeBeforeTargetTime = ({ departureDate, departure: { time: departureTime } = {} }) => {
  const targetTime = moment(`${departureDate}T${window.brandProps.muiTheme.targetTimeForAvailabilities}`)
  const currentSailTime = moment(`${departureDate}T${departureTime}`)
  return currentSailTime.isBefore(targetTime)
}

const checkIsSailTimeNotBeforeTargetTime = (sail) => !checkIsSailTimeBeforeTargetTime(sail)

export const getSailsWithDepartureTimeLessThanTargetTime = (sails = []) => {
  return sails.filter(checkIsSailTimeBeforeTargetTime)
}

export const getSailsWithDepartureTimeNotLessThanTargetTime = (sails = []) => {
  return sails.filter(checkIsSailTimeNotBeforeTargetTime)
}

export const addIsNextDayFlagToSails = (sails = []) => {
  return sails.map((sail) => ({ ...sail, isNextDay: true }))
}
