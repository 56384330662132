import { getUserGuideImagesFailed, getUserGuideImagesStart, getUserGuideImagesSuccess } from '../actions/userGuide'

const initialUserGuideState = {
  loading: false,
  error: '',
  images: [],
}

export default function userGuideReducer(state = initialUserGuideState, action) {
  switch (action.type) {
    case getUserGuideImagesStart().type:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case getUserGuideImagesSuccess().type:
      return {
        ...state,
        images: action.payload,
        loading: false,
      }
    case getUserGuideImagesFailed().type:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
