export const SET_SECOND_SAIL_DATE = 'user-selections:SET_SECOND_SAIL_DATE'
export const SET_SAIL_DATES = 'user-selections:SET_SAIL_DATES'
export const SET_FIRST_SAIL_DATE = 'user-selections:SET_FIRST_SAIL_DATE'
export const CHANGE_ITEM_QTTY = 'ticket-selections:CHANGE_ITEM_QTTY'
export const RESET_ALL_FORMS = 'user-selections:RESET_ALL_FORMS'
export const START_EDIT_TRIP = 'user-selections:START_EDIT_TRIP'
export const END_EDIT_TRIP = 'user-selections:END_EDIT_TRIP'
export const RESET_EDIT_TRIP = 'user-selections:RESET_EDIT_TRIP'
export const CHANGE_VEHICLE_INFO = 'user-selections:CHANGE_VEHICLE_INFO'
export const CHANGE_LOCALE = 'user-selections:CHANGE_LOCALE'
export const REMOVE_TRIP_PACKAGE = 'user-selections:REMOVE_TRIP_PACKAGE'
export const CHANGE_LOCAL_TRAVELLERS_QUANTITY = 'user-selections:CHANGE_LOCAL_TRAVELLERS_QUANTITY'
export const TOGGLE_MOBILE_MENU = 'user-selections:TOGGLE_MOBILE_MENU'
export const SELECT_CUSTOMER_ROLE = 'user-selections:SELECT_CUSTOMER_ROLE'
export const RESET_CUSTOMER_ROLE = 'user-selections:RESET_CUSTOMER_ROLE'

export const SELECT_SAIL_PACKAGE = 'user-selections:SELECT_SAIL_PACKAGE'
export const SELECT_REVERSE_SAIL_PACKAGE = 'user-selections:SELECT_REVERSE_SAIL_PACKAGE'
export const ADD_SAIL_PACKAGE_TO_SELECTED = 'user-selections:ADD_SAIL_PACKAGE_TO_SELECTED'
export const DROP_REVERSE_SAIL_PACKAGE = 'user-selections:DROP_REVERSE_SAIL_PACKAGE'
export const SET_SELECTED_PACKAGES = 'user-selections:SET_SELECTED_PACKAGES'

export const SET_COMMON_USER = 'user-selections:SET_COMMON_USER'
export const ADD_SEAT = '[SEATS]:ADD_SEAT'
export const FLUSH_SEAT = '[SEATS]:FLUSH_SEAT'
export const VEHICLE_INPUT_TYPE = 'VEHICLE_INPUT_TYPE'
export const TRAILER_INPUT_TYPE = 'TRAILER_INPUT_TYPE'

export const RESET_ALL = 'user-selections:RESET_ALL'
export const SELECT_PASSENGER_TO_EDIT_PERSONAL_DATA = 'user-selections:SELECT_PASSENGER_TO_EDIT_PERSONAL_DATA'
export const CLEAR_PASSENGER_SEQ_NUM = 'user-selections:CLEAR_PASSENGER_SEQ_NUM'

export const SAVE_PASSENGER_FORM_DATA = 'user-selections:SAVE_PASSENGER_FORM_DATA'
export const REMOVE_ONE_PASSENGER_FORM_DATA = 'user-selections:REMOVE_ONE_PASSENGER_FORM_DATA'
export const CLEAR_ALL_PASSENGER_FORM_DATA = 'user-selections:CLEAR_ALL_PASSENGER_FORM_DATA'
export const FILL_ROUTE_SELECTION_FROM_URL = 'user-selections:FILL_ROUTE_SELECTION_FROM_URL'

export const SET_IS_CHARTER_ADDING = 'user-selections:SET_IS_CHARTER_ADDING'
