/* eslint-disable complexity */
import { LOGOUT_SUCCESS, SIGNOUT_SUCCESS } from '../../auth/consts'
import * as Actions from '../consts'
import {
  getGroupsWithModifiedGroup,
  getGroupsWithoutDeletedGroup,
  getMembersAfterRemovingCustomer,
  getMembersWithModifiedMember,
} from '../utils'

const initialState = {
  members: [],
  groups: [],
  groupTickets: [],
  groupTicketsLoading: false,
  savedGuests: [],
  error: '',
}

export default function (state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    // Get and set all members
    case Actions.GET_ALL_MEMBERS:
      return {
        ...state,
        error: '',
      }
    case Actions.GET_ALL_MEMBERS_SUCCESS:
      return {
        ...state,
        members: payload,
      }
    case Actions.GET_ALL_MEMBERS_FAIL:
      return {
        ...state,
        error: payload,
      }

    // Get and set all groups
    case Actions.GET_GROUPS_WITH_THEIR_MEMBERS:
      return {
        ...state,
        error: '',
      }
    case Actions.GET_GROUPS_WITH_THEIR_MEMBERS_SUCCESS:
      return {
        ...state,
        groups: payload,
      }
    case Actions.GET_GROUPS_WITH_THEIR_MEMBERS_FAIL:
      return {
        ...state,
        error: payload,
      }

    // Delete member
    case Actions.DELETE_MEMBER:
      return {
        ...state,
        error: '',
      }
    case Actions.DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        members: getMembersAfterRemovingCustomer(state.members, payload),
      }
    case Actions.DELETE_MEMBER_FAIL:
      return {
        ...state,
        error: payload,
      }

    // Delete group
    case Actions.DELETE_GROUP:
      return {
        ...state,
        error: '',
      }
    case Actions.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        groups: getGroupsWithoutDeletedGroup(state.groups, payload),
      }
    case Actions.DELETE_GROUP_FAIL:
      return {
        ...state,
        error: payload,
      }

    // Add group
    case Actions.ADD_GROUP:
      return {
        ...state,
        error: '',
      }
    case Actions.ADD_GROUP_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, payload],
      }
    case Actions.ADD_GROUP_FAIL:
      return {
        ...state,
        error: payload,
      }

    // Modify group
    case Actions.MODIFY_GROUP:
      return {
        ...state,
        error: '',
      }
    case Actions.MODIFY_GROUP_SUCCESS:
      return {
        ...state,
        groups: getGroupsWithModifiedGroup(state.groups, payload),
      }
    case Actions.MODIFY_GROUP_FAIL:
      return {
        ...state,
        error: payload,
      }

    // Add member
    case Actions.ADD_MEMBER:
      return {
        ...state,
        error: '',
      }
    case Actions.ADD_MEMBER_SUCCESS:
      return {
        ...state,
        members: [...state.members, payload],
      }
    case Actions.ADD_MEMBER_FAIL:
      return {
        ...state,
        error: payload,
      }

    // Modify member
    case Actions.MODIFY_MEMBER:
      return {
        ...state,
        error: '',
      }
    case Actions.MODIFY_MEMBER_SUCCESS:
      return {
        ...state,
        members: getMembersWithModifiedMember(state.members, payload),
      }
    case Actions.MODIFY_MEMBER_FAIL:
      return {
        ...state,
        error: payload,
      }

    // Get group tickets
    case Actions.GET_GROUP_TICKETS:
      return {
        ...state,
        error: '',
      }
    case Actions.GET_GROUP_TICKETS_START:
      return {
        ...state,
        groupTicketsLoading: true,
        error: '',
      }
    case Actions.GET_GROUP_TICKETS_SUCCESS:
      return {
        ...state,
        groupTicketsLoading: false,
        groupTickets: payload,
      }
    case Actions.GET_GROUP_TICKETS_FAIL:
      return {
        ...state,
        groupTicketsLoading: false,
        error: payload,
      }

    // Add seqN of saved guest
    case Actions.ADD_SAVED_GUEST:
      return {
        ...state,
        savedGuests: [...state.savedGuests, payload],
      }

    // Reset group tickets
    case Actions.RESET_GROUP_TICKETS:
      return {
        ...state,
        groupTickets: [],
      }

    case SIGNOUT_SUCCESS:
    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}
