import { prop } from 'ramda'
import * as userActions from '../consts'
import * as customerActions from '../../customer/consts'
import { LOGOUT_SUCCESS, SIGNOUT_SUCCESS } from '../../auth/consts'

const initialUserDetails = {}

const details = (state = initialUserDetails, action) => {
  switch (action.type) {
    case customerActions.FETCH_USER_CUSTOMER_INFO_SUCCESS: {
      const { payload = { memberOf: [] } } = action

      return {
        ...state,
        customerId: payload.customerId,
        isManager: payload.memberOf.some(prop('manager')),
      }
    }

    case userActions.FETCH_USER_DETAILS_SUCCESS:
    case userActions.UPDATE_USER_DETAILS_SUCCESS:
    case userActions.SET_USER_DETAILS:
      return { ...state, ...action.payload }
    case SIGNOUT_SUCCESS:
    case LOGOUT_SUCCESS:
    case userActions.RESET_USER: {
      return initialUserDetails
    }
    case userActions.FETCH_USER_DETAILS_FAIL:
    case userActions.UPDATE_USER_DETAILS_FAIL:
    default:
      return state
  }
}

export default details
