import { combineReducers } from 'redux'
import details from './details'
import reservations from './reservations'
import companies from './companies'
import credentials from './credentials'
import filters from './filters'
import companyMembers from './companyMembers'
import countries from './countries'
import localTraveller from './localTraveller'
import citizenships from './citizenships'

export default combineReducers({
  details,
  reservations,
  filters,
  companies,
  credentials,
  companyMembers,
  countries,
  citizenships,
  localTraveller,
})
