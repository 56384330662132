export const getCompaniesAfterRemovingCurrentCustomerFromMembersList = (companies, curentCustomerId) => {
  return companies.map((company) => {
    const listOfMembersWithoutManagers = company.members.filter((member) => {
      return member.customerId !== curentCustomerId
    })
    return {
      ...company,
      members: listOfMembersWithoutManagers,
    }
  })
}

export const getMembersAfterRemovingCustomer = (members, currentCustomerId) => {
  return members.filter((member) => member.customerId !== currentCustomerId)
}

export const getGroupsWithoutDeletedGroup = (groups, deletedGroupId) => {
  return groups.filter((group) => group.companyId !== deletedGroupId)
}

export const getGroupsWithModifiedGroup = (groups, modifiedGroup) => {
  return groups.map((group) => {
    if (group.companyId === modifiedGroup.companyId) {
      return modifiedGroup
    }
    return group
  })
}

export const getMembersWithModifiedMember = (members, modifiedMember) => {
  return members.map((member) => {
    if (member.customerId === modifiedMember.customerId) {
      return modifiedMember
    }
    return member
  })
}
