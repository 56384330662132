import axios from 'axios'

export function getAllShips(locale) {
  return axios.get('/api/sales-static/ships', {
    params: { locale },
  })
}

export function getShipDetails(slug, locale) {
  return axios.get('/api/sales-static/ships', {
    params: { slug, locale },
  })
}
