import { LOGOUT_SUCCESS, SIGNOUT_SUCCESS } from '../../auth/consts'
import * as Actions from '../consts'

const initialCitizenships = []

const citizenships = (state = initialCitizenships, action) => {
  switch (action.type) {
    case Actions.FETCH_CITIZENSHIPS_SUCCESS: {
      return action.payload || []
    }

    case SIGNOUT_SUCCESS:
    case LOGOUT_SUCCESS:
      return initialCitizenships

    default:
      return state
  }
}

export default citizenships
