/* eslint-disable prefer-const */
import moment from 'moment'

import { call, put, takeEvery, select } from 'redux-saga/effects'
import {
  FETCH_AVAILABLE_REPLACEMENT_LEGS,
  FETCH_AVAILABLE_REPLACEMENT_LEGS_SUCCESS,
  FETCH_AVAILABLE_REPLACEMENT_LEGS_FAIL,
  FETCH_SAILS_SCHEDULE,
  FETCH_SAILS_FOR_LEG_AND_DATE,
  FETCH_SCHEDULE_AVAILABLE_DATES_SUCCESS,
  FETCH_SCHEDULE_AVAILABLE_DATES_FAIL,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAIL,
  MANAGE_SCHEDULE_PARTS_FETCH_RESPONSE,
  SW_SAILS_FETCH,
  OMIT_PACKAGE_FROM_SCHEDULE_STATE,
} from './consts/schedule'
import schedule from './actions'
import {
  fetchAvailableReplacementLegs,
  fetchSailsDates,
  fetchSails,
  fetchSailPackageDates,
  fetchPackageSails,
} from './api/schedule'
import { sailsInventoriesMutator } from '../inventory/utils'

import { DATE_FORMAT } from '../../consts/stuff'
import { ADD_SAIL_PACKAGE_TO_SELECTED } from '../user-selections/consts'
import { SET_EDITED_RESERVATION_DATA } from '../reservation/consts/reservation'
import {
  addIsNextDayFlagToSails,
  getSailsWithDepartureTimeLessThanTargetTime,
  getSailsWithDepartureTimeNotLessThanTargetTime,
} from './utils'
import * as R from 'ramda'
import { makeSafeRequest } from '../../utils/makeSafeRequest'

function* fetchAvailableReplacementLegsSaga({ payload: sailRefId }) {
  try {
    const { availableReplacementLegs } = yield call(fetchAvailableReplacementLegs, sailRefId)
    yield put({
      type: FETCH_AVAILABLE_REPLACEMENT_LEGS_SUCCESS,
      payload: {
        availableReplacementLegs,
      },
    })
  } catch (e) {
    yield put({
      type: FETCH_AVAILABLE_REPLACEMENT_LEGS_FAIL,
    })
  }
}

function* fetchSailsAvailableDatesSaga({ payload = {} }) {
  let { legCode, startDate, endDate, isTaxi } = payload

  if (!legCode) {
    const [leg] = payload.legSequence

    legCode = leg.code
    const startMoment = moment().startOf('date')
    startDate = startMoment.format(DATE_FORMAT)
    endDate = moment(startMoment).add(12, 'M').endOf('month').format(DATE_FORMAT)
    // endDate = '2019-06-03' // <- freaking hardcode as kihnu wanted
  }

  try {
    const data = isTaxi
      ? yield call(fetchSailPackageDates, { legCode, startDate, endDate })
      : yield call(fetchSailsDates, { legCode, startDate, endDate })
    yield put({
      type: FETCH_SCHEDULE_AVAILABLE_DATES_SUCCESS,
      payload: {
        sailPackageCode: legCode,
        availableDates: data || [],
      },
    })
  } catch (e) {
    yield put({
      type: FETCH_SCHEDULE_AVAILABLE_DATES_FAIL,
      payload: { sailPackageCode: legCode },
    })
  }
}

function* fetchSailsByLegAndDateSaga({ payload: { leg, code, date, isTaxi } }) {
  try {
    const { content: sails = [] } = isTaxi
      ? yield call(fetchPackageSails, { leg, date })
      : yield call(fetchSails, { leg, date })

    const nextDayDate = moment(date).add(1, 'days').format('YYYY-MM-DD')

    const { data: { content: nextDaySails = [] } = {} } = isTaxi
      ? yield call(makeSafeRequest, () => fetchPackageSails({ leg, date: nextDayDate }))
      : yield call(makeSafeRequest, () => fetchSails({ leg, date: nextDayDate }))

    const currentAndNextDaySails = [
      ...getSailsWithDepartureTimeNotLessThanTargetTime(sails),
      ...R.pipe(getSailsWithDepartureTimeLessThanTargetTime, addIsNextDayFlagToSails)(nextDaySails),
    ]

    yield put({
      type: SW_SAILS_FETCH,
      meta: { WebWorker: true },
      payload: { leg, date, code },
    })

    yield put({
      type: FETCH_SCHEDULE_SUCCESS,
      payload: {
        sailPackageCode: code,
        sails: currentAndNextDaySails.map((sail) => ({
          ...sail,
          sailPackage: code,
          terminalLocation: R.pathOr(null, ['sailPackage', 'portTo'])(sail),
        })),
      },
    })
    const editReservation = yield select((state) => state.reservation.edit)
    if (editReservation && currentAndNextDaySails[0]) {
      yield put({
        type: SET_EDITED_RESERVATION_DATA,
        payload: {
          field: 'time',
          param: currentAndNextDaySails[0].departure.time,
        },
      })
    }
  } catch (e) {
    yield put({
      type: FETCH_SCHEDULE_FAIL,
    })
  }
}

function* setState({ payload: { availableDates, sails, inventories: payloadInventories, selectedSails } }) {
  const inventories = {}
  Object.keys(payloadInventories).forEach((key) => {
    inventories[key] = sailsInventoriesMutator(payloadInventories[key])
  })

  const scheduleState = {
    sails,
    availableDates,
    inventories,
    selectedSails,
  }

  yield put(schedule.setScheduleState(scheduleState))
}

function* clearSWTimeouts() {
  yield put(schedule.clearSWTimeouts())
}

function* getLegsBySailRefId() {
  yield takeEvery(FETCH_AVAILABLE_REPLACEMENT_LEGS, fetchAvailableReplacementLegsSaga)
}

function* getAvailableDates() {
  yield takeEvery(FETCH_SAILS_SCHEDULE, fetchSailsAvailableDatesSaga)
  yield takeEvery(ADD_SAIL_PACKAGE_TO_SELECTED, fetchSailsAvailableDatesSaga)
}

function* getSailsByLeg() {
  yield takeEvery(FETCH_SAILS_FOR_LEG_AND_DATE, fetchSailsByLegAndDateSaga)
}

function* watchFetchSailPackageResponse() {
  yield takeEvery(MANAGE_SCHEDULE_PARTS_FETCH_RESPONSE, setState)
}

function* watchOmitSailPackageFromSchedule() {
  yield takeEvery(OMIT_PACKAGE_FROM_SCHEDULE_STATE, clearSWTimeouts)
}

export function* scheduleSaga() {
  yield [
    getLegsBySailRefId(),
    getAvailableDates(),
    getSailsByLeg(),
    watchFetchSailPackageResponse(),
    watchOmitSailPackageFromSchedule(),
  ]
}
