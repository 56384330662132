import * as Actions from '../consts'
import * as userActions from '../../user/consts'
import { LOGOUT_SUCCESS, SIGNOUT_SUCCESS } from '../../auth/consts'

const initialCustomer = {
  accounts: [],
  ageInYears: null,
  birthday: '',
  createdAt: '',
  customerId: null,
  email: '',
  firstName: '',
  gender: '',
  lastName: '',
  memberOf: [],
  personalIdentificationNumber: '',
  phone: {},
  status: '',
  updatedAt: '',
  userIds: [],
}

const customer = (state = initialCustomer, action) => {
  const { payload } = action
  switch (action.type) {
    case Actions.FETCH_USER_CUSTOMER_INFO_SUCCESS: {
      return {
        ...state,
        ...payload,
      }
    }

    case SIGNOUT_SUCCESS:
    case LOGOUT_SUCCESS:
    case userActions.RESET_USER: {
      return initialCustomer
    }

    default:
      return state
  }
}

export default customer
