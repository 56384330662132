import moment from 'moment'
import { normalize } from 'normalizr'
import { mergeDeepRight, reduce, find, propEq, __, compose, pathOr, curry } from 'ramda'
import { THEME } from 'bora-material-ui/themes'
import { DEFAULT_PHONE_CODE } from '../modules/Booking/forms/CustomerInfo/consts'

const getLegBySegNumber = (sn) => find(propEq('seqNumber', sn))
const getLegsForPackage = (sLeg, eLeg, legs) => {
  if (sLeg === eLeg) return [getLegBySegNumber(sLeg)(legs)]
  return [getLegBySegNumber(sLeg)(legs), getLegBySegNumber(eLeg)(legs)]
}

const generatePackage = (code, route, type, startLeg, endLeg) => ({
  code,
  route: route.code,
  type,
  legSequence: getLegsForPackage(startLeg, endLeg, route.legs),
  source: 'promotions',
})

export const extendSailPackage = (payload) => {
  if (typeof payload.route === 'object') {
    return {
      ...payload,
      route: payload.route.code,
      attributes: payload.route.attributes,
      legSequence: getLegsForPackage(payload.startLeg, payload.endLeg, payload.route.legs),
      source: 'search-bar',
    }
  }

  return payload
}

export const getRoutesFromPackages = reduce((routes, sailPackage) => {
  const { route, type, startLeg, endLeg, code: sailPackageId } = sailPackage

  if (routes[route.code]) {
    return {
      ...routes,
      [route.code]: {
        ...routes[route.code],
        sailPackages: [
          ...routes[route.code].sailPackages,
          generatePackage(sailPackageId, route, type, startLeg, endLeg),
        ],
      },
    }
  }

  return {
    ...routes,
    [route.code]: {
      ...route,
      sailPackages: [generatePackage(sailPackageId, route, type, startLeg, endLeg)],
    },
  }
}, {})

export const getServices = (...arg) => arg.reduce((services, service) => mergeDeepRight(services, service), {})

export const normalizeData = (key, schema) =>
  compose(pathOr({}, ['entities', key]), curry(normalize)(__, schema), pathOr({}, ['content']))

export const beforeThanToday = (date) => (date ? moment(date, 'YYYY-MM-DD').isBefore(moment(), 'day') : false)

export const getDataFromURL = (data = '') => {
  let result = ''
  if (window && window.location.href.includes(data)) {
    result = window.location.href.slice(window.location.href.indexOf(data))
  }
  return result
}

export const getPhoneCode = (theme = '') => {
  if (theme === THEME.KIHNU || theme === THEME.SAAREMAA || theme === THEME.LIINILAEVAD) {
    return DEFAULT_PHONE_CODE
  }
  return ''
}

export const capitalize = (str) => (str && str[0].toUpperCase() + str.slice(1)) || ''
