import axios from 'axios'

export const addOrModifyGroup = (data) =>
  axios({
    method: 'put',
    url: 'api/group',
    data,
  })

export const addOrModifyMember = (data) =>
  axios({
    method: 'put',
    url: 'api/member',
    data,
  })

export const getGroupsWithTheirMembers = () =>
  axios({
    method: 'get',
    url: 'api/groups/by/user',
  })

export const getAllMembersWithTheirGroups = () =>
  axios({
    method: 'get',
    url: 'api/customers',
  })

export const deleteGroup = (groupId) =>
  axios({
    method: 'delete',
    url: `api/group/${groupId}`,
  })

export const deleteMember = (customerId) =>
  axios({
    method: 'delete',
    url: `api/customer/${customerId}`,
  })

export const getGroupTickets = (data) =>
  axios({
    method: 'put',
    url: 'api/groupTickets',
    data,
  })
