import { LOGOUT_SUCCESS, SIGNOUT_SUCCESS } from '../../auth/consts'
import * as Actions from '../consts'

const initialMembers = {
  fetching: false,
  byCompanyId: {},
}

const companyMembers = (state = initialMembers, action) => {
  switch (action.type) {
    case Actions.FETCH_COMPANY_MEMBERS: {
      return { ...state, fetching: true }
    }

    case Actions.FETCH_COMPANY_MEMBERS_SUCCESS:
      return { ...state, byCompanyId: { ...action.payload }, fetching: false }

    case Actions.UPDATE_COMPANY_MEMBERS: {
      const { companyId, ...payload } = action.payload

      return {
        ...state,
        byCompanyId: {
          ...state.byCompanyId,
          [companyId]: {
            ...state.byCompanyId[companyId],
            ...payload,
          },
        },
      }
    }

    case SIGNOUT_SUCCESS:
    case LOGOUT_SUCCESS:
    case Actions.RESET_USER: {
      return initialMembers
    }

    default:
      return state
  }
}

export default companyMembers
