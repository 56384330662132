import { createAction } from 'redux-actions'

import {
  START_EDIT_TRIP,
  END_EDIT_TRIP,
  RESET_EDIT_TRIP,
  SET_SECOND_SAIL_DATE,
  SET_SAIL_DATES,
  SET_FIRST_SAIL_DATE,
  CHANGE_ITEM_QTTY,
  RESET_ALL_FORMS,
  CHANGE_VEHICLE_INFO,
  CHANGE_LOCALE,
  CHANGE_LOCAL_TRAVELLERS_QUANTITY,
  TOGGLE_MOBILE_MENU,
  REMOVE_TRIP_PACKAGE,
  SELECT_CUSTOMER_ROLE,
  RESET_CUSTOMER_ROLE,
  SELECT_REVERSE_SAIL_PACKAGE,
  SELECT_SAIL_PACKAGE,
  ADD_SAIL_PACKAGE_TO_SELECTED,
  DROP_REVERSE_SAIL_PACKAGE,
  SET_SELECTED_PACKAGES,
  SET_COMMON_USER,
  ADD_SEAT,
  FLUSH_SEAT,
  VEHICLE_INPUT_TYPE,
  TRAILER_INPUT_TYPE,
  RESET_ALL,
  SELECT_PASSENGER_TO_EDIT_PERSONAL_DATA,
  CLEAR_PASSENGER_SEQ_NUM,
  SAVE_PASSENGER_FORM_DATA,
  CLEAR_ALL_PASSENGER_FORM_DATA,
  REMOVE_ONE_PASSENGER_FORM_DATA,
  FILL_ROUTE_SELECTION_FROM_URL,
  SET_IS_CHARTER_ADDING,
} from './consts'

export const setSailDates = createAction(SET_SAIL_DATES)
export const startEditTrip = createAction(START_EDIT_TRIP)
export const endEditTrip = createAction(END_EDIT_TRIP)
export const resetEditTrip = createAction(RESET_EDIT_TRIP)
export const setFirstSailDate = createAction(SET_FIRST_SAIL_DATE)
export const setSecondSailDate = createAction(SET_SECOND_SAIL_DATE)
export const changeItemQtty = createAction(CHANGE_ITEM_QTTY)
export const resetAllForms = createAction(RESET_ALL_FORMS)
export const removeTripPackage = createAction(REMOVE_TRIP_PACKAGE)
export const changeVehicleData = createAction(CHANGE_VEHICLE_INFO)
export const changeLocale = createAction(CHANGE_LOCALE)
export const changeLocalTravellersQuantity = createAction(CHANGE_LOCAL_TRAVELLERS_QUANTITY)
export const toggleMobileMenu = createAction(TOGGLE_MOBILE_MENU)
export const selectCustomerRole = createAction(SELECT_CUSTOMER_ROLE)
export const resetCustomerRole = createAction(RESET_CUSTOMER_ROLE)

export const selectSailPackage = createAction(SELECT_SAIL_PACKAGE)
export const selectReverseSailPackage = createAction(SELECT_REVERSE_SAIL_PACKAGE)
export const addToSelectedSailPackage = createAction(ADD_SAIL_PACKAGE_TO_SELECTED)
export const setSelectedPackages = createAction(SET_SELECTED_PACKAGES)
export const dropReverseSailPackage = createAction(DROP_REVERSE_SAIL_PACKAGE)

export const setCommonUser = createAction(SET_COMMON_USER)
export const addSeat = createAction(ADD_SEAT)
export const flushSeats = createAction(FLUSH_SEAT)
export const setVehicleInputType = createAction(VEHICLE_INPUT_TYPE)
export const setTrailerInputType = createAction(TRAILER_INPUT_TYPE)
export const resetAllUserSelections = createAction(RESET_ALL)
export const selectPassengerToEditPersonalData = createAction(SELECT_PASSENGER_TO_EDIT_PERSONAL_DATA)
export const clearPassengerSeqNum = createAction(CLEAR_PASSENGER_SEQ_NUM)
export const saveTempPassengerData = createAction(SAVE_PASSENGER_FORM_DATA)
export const clearAllTempPassengerData = createAction(CLEAR_ALL_PASSENGER_FORM_DATA)
export const removeTempOnePassengerData = createAction(REMOVE_ONE_PASSENGER_FORM_DATA)
export const fillSelectionsFromUrl = createAction(FILL_ROUTE_SELECTION_FROM_URL)

export const setIsChaterAdding = createAction(SET_IS_CHARTER_ADDING)
