import { pathOr } from 'ramda'
import { createAction } from 'redux-actions'

export const SET_REF = 'scroll/SET_REF'

const initialState = null

const scroll = (state = initialState, action) => {
  switch (action.type) {
    case SET_REF: {
      return pathOr(state, ['payload'], action)
    }
    default:
      return state
  }
}

export const setScrollRef = createAction(SET_REF)

export default scroll
