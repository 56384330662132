import moment from 'moment'
import { propOr } from 'ramda'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { changeLocale, getLocale, toggleMobileMenu } from '../user-selections'
import {
  getUserGuideImages,
  getUserGuideImagesFailed,
  getUserGuideImagesStart,
  getUserGuideImagesSuccess,
} from './actions/userGuide'
import { fetchUserGuideImages, getMenuItems } from './api'
import { FETCH_MENU_ITEMS_FAILED, FETCH_MENU_ITEMS_SUCCESS } from './consts/menuItems'
// import { getUserGuideData } from './selectors'
import { getImageLinks } from './utils'

function* fetchMenuItems() {
  yield put(toggleMobileMenu(false))
  const locale = yield select(getLocale())
  try {
    const data = yield call(getMenuItems, locale)
    moment.locale(locale)
    yield put({
      type: FETCH_MENU_ITEMS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    yield put({
      type: FETCH_MENU_ITEMS_FAILED,
      payload: error,
    })
  }
}

function* watchLocaleChange() {
  yield takeLatest(changeLocale, fetchMenuItems)
}

function* getUserGuideImagesWorker() {
  const locale = yield select(getLocale)
  // const { images } = yield select(getUserGuideData)
  // if (images.length) {
  //   return
  // }

  try {
    yield put(getUserGuideImagesStart())
    const data = yield call(fetchUserGuideImages, locale)
    const images = propOr([], 'images')(data)
    const imageLinks = getImageLinks(images)
    yield put(getUserGuideImagesSuccess(imageLinks))
  } catch (err) {
    yield put(getUserGuideImagesFailed(err.message))
  }
}

function* watchGetUserGuideImages() {
  yield takeLatest(getUserGuideImages, getUserGuideImagesWorker)
}

export function* salesStaticSaga() {
  yield [fetchMenuItems(), watchLocaleChange(), watchGetUserGuideImages()]
}
