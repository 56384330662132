import faker from 'faker'

export const isReallyProduction = () => window.brandProps && window.brandProps.env === 'production'

export const fillContactDataForm = (change) => {
  if (isReallyProduction()) return

  const firstName = faker.name.firstName()
  const lastName = faker.name.lastName()
  const phoneCode = '372'
  const phoneNumber = faker.phone.phoneNumber('55######')
  const email = faker.internet.email()

  change('phoneCode', phoneCode)
  change('firstName', firstName)
  change('lastName', lastName)
  change('phoneNumber', phoneNumber)
  change('email', email)
}

export const fillBookingPassengerDataForm = (change, formName, passenger) => {
  if (isReallyProduction()) return

  const firstName = faker.name.firstName()
  const lastName = faker.name.lastName()

  change(formName, `${passenger}.firstName`, firstName)
  change(formName, `${passenger}.lastName`, lastName)
  change(formName, `${passenger}.gender`, 'M')
  const date = faker.date.past(50).getDate()
  change(formName, `${passenger}.birthday.date`, date > 28 ? 28 : date)
  change(formName, `${passenger}.birthday.month`, faker.date.past(50).getMonth())
  change(formName, `${passenger}.birthday.year`, faker.random.number({ max: 122, min: 0 }))
}

export const fillCompanyDataForm = (change) => {
  if (isReallyProduction()) return

  change('city', faker.address.city())
  change('legalName', faker.company.companyName())
  change('zip', faker.address.zipCode())
  change('registrationNumber', faker.finance.creditCardNumber())
  change('addressLine', faker.address.streetAddress())
}
