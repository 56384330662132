import { createAction } from 'redux-actions'
import {
  GET_USER_GUIDE_IMAGES,
  GET_USER_GUIDE_IMAGES_FAILED,
  GET_USER_GUIDE_IMAGES_START,
  GET_USER_GUIDE_IMAGES_SUCCESS,
} from '../consts/userGuide'

export const getUserGuideImages = createAction(GET_USER_GUIDE_IMAGES)
export const getUserGuideImagesStart = createAction(GET_USER_GUIDE_IMAGES_START)
export const getUserGuideImagesSuccess = createAction(GET_USER_GUIDE_IMAGES_SUCCESS)
export const getUserGuideImagesFailed = createAction(GET_USER_GUIDE_IMAGES_FAILED)
