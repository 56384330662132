import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as R from 'ramda'

import {
  FETCH_PRICES,
  FETCH_PRICES_FAIL,
  FETCH_PRICES_FOR_SELECTED_SAILS,
  FETCH_PRICES_FOR_SELECTED_SAILS_FAIL,
  FETCH_PRICES_SUCCESS,
  SET_PRICES,
  CALCULATE_VEHICLE_PRICE,
  CALCULATE_VEHICLE_PRICE_SUCCESS,
  CALCULATE_VEHICLE_PRICE_FAIL,
  CALCULATE_VEHICLE_REQUESTING,
} from './consts'

import {
  getPrices,
  fetchPricesBySailRefsIds,
  calculateVehiclePrices,
  fetchPackagePricesBySailRefsIds,
} from './api/prices'
import {
  selectedSails as getSelectedSails,
  selectedSailsDefault as getSelectedSailsDefault,
} from '../schedule/reducers/schedule'

import { changeLocale } from '../user-selections/actions'
import { isRoundTrip, getSailDates, getFirstSelectedSailPackage } from '../user-selections'

function* fetchPrices({ payload: sailRefId }) {
  try {
    const prices = yield call(getPrices, sailRefId)
    yield put({
      type: FETCH_PRICES_SUCCESS,
      payload: {
        prices,
        sailRefId,
      },
    })
  } catch (e) {
    yield put({
      type: FETCH_PRICES_FAIL,
      payload: {
        sailRefId,
      },
    })
  }
}

function* fetchPricesForSelectedSails({ payload = {} }) {
  const { sailRefIds } = payload
  try {
    const selectedSails = yield select(getSelectedSails)
    const selectedSailsDefault = yield select(getSelectedSailsDefault)
    const selectedSailRefIdsDefault = [R.values(selectedSailsDefault).flat()[0].sailRefId]
    const selectedSail =
      R.values(selectedSails) && R.values(selectedSails).length ? R.values(selectedSails) : selectedSailRefIdsDefault
    const selectedSailRefIds = sailRefIds || selectedSail
    const rt = yield select(isRoundTrip)
    const [date1, date2] = yield select(getSailDates)
    const { code: sailPackageCode, taxi } = yield select(getFirstSelectedSailPackage)
    const isTaxi = Boolean(taxi)
    const oneDayRoundTrip = rt && date1 === date2
    const selectedSailRefIdsWithSelectedSailPackageCode = selectedSailRefIds.map((sailRefId) => ({
      sailPackage: sailPackageCode,
      sailRefId,
    }))

    const prices = isTaxi
      ? yield call(fetchPackagePricesBySailRefsIds(rt, oneDayRoundTrip), selectedSailRefIdsWithSelectedSailPackageCode)
      : yield call(fetchPricesBySailRefsIds(rt, oneDayRoundTrip), selectedSailRefIds)

    yield put({
      type: SET_PRICES,
      payload: R.zipObj(selectedSailRefIds, prices),
    })
  } catch (e) {
    yield put({
      type: FETCH_PRICES_FOR_SELECTED_SAILS_FAIL,
    })
  }
}

function* calculatePriceForCar({ payload }) {
  try {
    const { formName } = payload
    const reservationId = yield select(R.pathOr(0, ['reservation', 'current', 'reservationId']))

    yield put({
      type: CALCULATE_VEHICLE_REQUESTING,
      payload: true,
    })

    const vehiclePrice = yield call(calculateVehiclePrices, {
      ...payload,
      reservationId,
    })

    yield put({
      type: CALCULATE_VEHICLE_PRICE_SUCCESS,
      payload: {
        ...vehiclePrice,
        formName,
      },
    })
  } catch (e) {
    yield put({
      type: CALCULATE_VEHICLE_PRICE_FAIL,
    })
  } finally {
    yield put({
      type: CALCULATE_VEHICLE_REQUESTING,
      payload: false,
    })
  }
}

function* watchFetchPrices() {
  yield takeEvery(FETCH_PRICES, fetchPrices)
}

function* getPricesForSelectedSails() {
  yield takeEvery(FETCH_PRICES_FOR_SELECTED_SAILS, fetchPricesForSelectedSails)
}

function* watchLocaleChange() {
  yield takeLatest(changeLocale, fetchPricesForSelectedSails)
}

function* watchCalculatePrices() {
  yield takeEvery(CALCULATE_VEHICLE_PRICE, calculatePriceForCar)
}

export function* pricesSaga() {
  yield [watchFetchPrices(), getPricesForSelectedSails(), watchLocaleChange(), watchCalculatePrices()]
}
