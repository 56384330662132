import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as Actions from './consts'
import {
  addOrModifyGroup,
  addOrModifyMember,
  deleteGroup,
  getAllMembersWithTheirGroups,
  getGroupsWithTheirMembers,
  deleteMember,
  getGroupTickets,
} from './api'
import { getToken } from '../auth/reducers/selectors'
import { addSavedGuest } from './actions'
// import { getCompaniesAfterRemovingCurrentCustomerFromMembersList, getMembersAfterRemovingCustomer } from './utils'
// import { getCustomerId } from '../customer/selectors'

function* addGroupWorker(action) {
  try {
    const data = yield call(addOrModifyGroup, action.payload)
    yield put({
      type: Actions.ADD_GROUP_SUCCESS,
      payload: data,
    })
    yield put({
      type: Actions.GET_ALL_MEMBERS,
    })
  } catch (err) {
    yield put({
      type: Actions.ADD_GROUP_FAIL,
      payload: err.message,
    })
  }
}

function* modifyGroupWorker(action) {
  try {
    const data = yield call(addOrModifyGroup, action.payload)
    yield put({
      type: Actions.MODIFY_GROUP_SUCCESS,
      payload: data,
    })
    yield put({
      type: Actions.GET_ALL_MEMBERS,
    })
  } catch (err) {
    yield put({
      type: Actions.MODIFY_GROUP_FAIL,
      payload: err.message,
    })
  }
}

function* addMemberWorker(action) {
  try {
    const { seqN, ...payload } = action.payload || {}
    const member = yield call(addOrModifyMember, payload)
    const { memberOf } = member
    const customerId = memberOf && member.memberOf.customerId
    const companies = memberOf.map(({ company, ...props }) => {
      return {
        ...company,
        ...props,
      }
    })
    if (seqN) {
      yield put(addSavedGuest({ customerId, seqN }))
    }
    const { firstName, lastName } = member
    const fullName = `${firstName.trim()} ${lastName.trim()}`
    const memberWithFullName = {
      ...member,
      companies,
      fullName,
    }
    yield put({
      type: Actions.ADD_MEMBER_SUCCESS,
      payload: memberWithFullName,
    })
    yield put({
      type: Actions.GET_GROUPS_WITH_THEIR_MEMBERS,
    })
  } catch (err) {
    yield put({
      type: Actions.ADD_MEMBER_FAIL,
      payload: err.message,
    })
  }
}

function* modifyMemberWorker(action) {
  try {
    const member = yield call(addOrModifyMember, action.payload)
    const { memberOf } = member
    const companies = memberOf.map(({ company, ...props }) => {
      return {
        ...company,
        ...props,
      }
    })
    const { firstName, lastName } = member
    const fullName = `${firstName.trim()} ${lastName.trim()}`
    const memberWithFullName = {
      ...member,
      companies,
      fullName,
    }
    yield put({
      type: Actions.MODIFY_MEMBER_SUCCESS,
      payload: memberWithFullName,
    })
    yield put({
      type: Actions.GET_GROUPS_WITH_THEIR_MEMBERS,
    })
  } catch (err) {
    yield put({
      type: Actions.MODIFY_MEMBER_FAIL,
      payload: err.message,
    })
  }
}

function* getGroupsWithTheirMembersWorker() {
  try {
    const isLogged = yield select(getToken)
    if (!isLogged) {
      return
    }
    const { content: companies = [] } = yield call(getGroupsWithTheirMembers)
    // const currentCustomerId = yield select(getCustomerId)
    // const companiesWithoutCurrentCustomerInMembersList = getCompaniesAfterRemovingCurrentCustomerFromMembersList(
    //   companies,
    //   currentCustomerId
    // )
    yield put({
      type: Actions.GET_GROUPS_WITH_THEIR_MEMBERS_SUCCESS,
      payload: companies,
    })
  } catch (err) {
    yield put({
      type: Actions.GET_GROUPS_WITH_THEIR_MEMBERS_FAIL,
      payload: err.message,
    })
  }
}

function* getAllMembersWithTheirGroupsWorker() {
  try {
    const isLogged = yield select(getToken)
    if (!isLogged) {
      return
    }
    const members = yield getAllMembersWithTheirGroups()
    // const currentCustomerId = yield select(getCustomerId)
    // const membersWithoutCurrentCutomer = getMembersAfterRemovingCustomer(members, currentCustomerId)
    yield put({
      type: Actions.GET_ALL_MEMBERS_SUCCESS,
      payload: members,
    })
  } catch (err) {
    yield put({
      type: Actions.GET_ALL_MEMBERS_FAIL,
      payload: err.message,
    })
  }
}

function* deleteGroupWorker(action) {
  try {
    const { companyId: groupId } = action.payload
    yield call(deleteGroup, groupId)
    yield put({
      type: Actions.DELETE_GROUP_SUCCESS,
      payload: groupId,
    })
    yield put({
      type: Actions.GET_ALL_MEMBERS,
    })
  } catch (err) {
    yield put({
      type: Actions.DELETE_GROUP_FAIL,
      payload: err.message,
    })
  }
}

function* deleteMemberWorker(action) {
  try {
    const { customerId } = action.payload
    yield call(deleteMember, customerId)
    yield put({
      type: Actions.DELETE_MEMBER_SUCCESS,
      payload: customerId,
    })
    yield put({
      type: Actions.GET_GROUPS_WITH_THEIR_MEMBERS,
    })
  } catch (err) {
    yield put({
      type: Actions.DELETE_MEMBER_FAIL,
      payload: err.message,
    })
  }
}

function* getGroupTicketsWorker({ payload }) {
  try {
    const isLogged = yield select(getToken)
    if (!isLogged) {
      return
    }
    yield put({
      type: Actions.GET_GROUP_TICKETS_START,
    })
    const { content: groupTickets } = yield call(getGroupTickets, payload)
    yield put({
      type: Actions.GET_GROUP_TICKETS_SUCCESS,
      payload: groupTickets,
    })
  } catch (err) {
    yield put({
      type: Actions.GET_GROUP_TICKETS_FAIL,
      payload: err.message,
    })
  }
}

function* watchGetGroupTicketsWorker() {
  yield takeLatest(Actions.GET_GROUP_TICKETS, getGroupTicketsWorker)
}

function* watchGetAllMembersWithTheirGroupsWorker() {
  yield takeLatest(Actions.GET_ALL_MEMBERS, getAllMembersWithTheirGroupsWorker)
}

function* watchDeleteGroupWorker() {
  yield takeEvery(Actions.DELETE_GROUP, deleteGroupWorker)
}

function* watchDeleteMemberWorker() {
  yield takeEvery(Actions.DELETE_MEMBER, deleteMemberWorker)
}

function* watchAddGroupWorker() {
  yield takeEvery(Actions.ADD_GROUP, addGroupWorker)
}

function* watchModifyGroupWorker() {
  yield takeEvery(Actions.MODIFY_GROUP, modifyGroupWorker)
}

function* watchAddMemberWorker() {
  yield takeEvery(Actions.ADD_MEMBER, addMemberWorker)
}

function* watchModifyMemberWorker() {
  yield takeEvery(Actions.MODIFY_MEMBER, modifyMemberWorker)
}

function* watchGetGroupsWithTheirMembersWorker() {
  yield takeLatest(Actions.GET_GROUPS_WITH_THEIR_MEMBERS, getGroupsWithTheirMembersWorker)
}

export default function* customerSaga() {
  yield [
    watchAddGroupWorker(),
    watchModifyGroupWorker(),
    watchAddMemberWorker(),
    watchModifyMemberWorker(),
    watchGetGroupsWithTheirMembersWorker(),
    watchGetAllMembersWithTheirGroupsWorker(),
    watchDeleteGroupWorker(),
    watchDeleteMemberWorker(),
    watchGetGroupTicketsWorker(),
  ]
}
