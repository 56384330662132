import { select, put, call } from 'redux-saga/effects'
import { getCurrentReservation } from '../../../services/reservation/selectors'
import * as R from 'ramda'
import { reset, getFormValues, isValid } from 'redux-form'
import {
  DRIVER_DATE_OF_BIRTH,
  DRIVER_FIRST_NAME,
  DRIVER_GENDER,
  DRIVER_LAST_NAME,
  DRIVER_COUNTRY_OF_ISSUE,
  DRIVER_PASSPORT_NUMBER,
  DRIVER_PLACE_OF_BIRTH,
  FORM_ID,
} from '../../../modules/Booking/forms/CustomerInfo/bluewave/DriverFromConstants'
import { reservationModificationPassengerEditService } from '../../../services/reservation/api'
import { setError } from '../../../modules/Ticket/EditTicket/editTicketSagas'
import { isBluewave } from '../../../utils/maritimeStyleUtils'

const PRICE_CATEGORY = isBluewave ? 'BWDRIVER' : 'DRIVER'

const getSeqNumOfItemByPriceCategory = (priceCategory) =>
  R.compose(
    R.last,
    R.map(R.pathOr(0, ['ownerSeqNs', 0])),
    R.filter(R.propEq('priceCategory', priceCategory)),
    R.prop('items')
  )

export function* writeDriverDetailsIfNeeded() {
  const currentReservation = yield select(getCurrentReservation)
  const bwDriverSeqNum = getSeqNumOfItemByPriceCategory(PRICE_CATEGORY)(currentReservation)
  const isFormValid = yield select(isValid(FORM_ID))
  const formValues = yield select(getFormValues(FORM_ID))

  if (bwDriverSeqNum && isFormValid) {
    try {
      yield call(
        reservationModificationPassengerEditService,
        currentReservation.reservationId,
        {
          firstName: formValues[DRIVER_LAST_NAME],
          lastName: formValues[DRIVER_FIRST_NAME],
          seqN: bwDriverSeqNum,
          ...(isBluewave && {
            gender: formValues[DRIVER_GENDER],
            birthPlace: formValues[DRIVER_PLACE_OF_BIRTH],
            travelDocument: {
              number: formValues[DRIVER_PASSPORT_NUMBER],
              type: 'PASSPORT',
              countryOfIssue: formValues[DRIVER_COUNTRY_OF_ISSUE],
            },
            birthday: formValues[DRIVER_DATE_OF_BIRTH],
          }),
        },
        bwDriverSeqNum
      )

      yield put(reset(FORM_ID))
    } catch (e) {
      yield put(setError(`Cannot write guest data for category ${PRICE_CATEGORY} guest with seq num ${bwDriverSeqNum}`))
    }
  }
}
