/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import { curry, equals, complement, isNil, pathOr } from 'ramda'
import { isBluewave } from './maritimeStyleUtils'

const validator = (invalid, errorKey) => (invalid ? errorKey : undefined)

export const password = (password) => {
  const passwordRegExp = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/
  return validator(!passwordRegExp.test(password), 'invalid-password-text')
}

export const correctSymbolsRegExp = /^(?!\d)[^()?!~,.:;<>@#$^%{}|[\\\]]{1,}$/
export const phoneNumberRegExp = /^\+[1-9]{1}\d{0,3}[1-9]{1}\d{6,14}$/
export const emailRegExp =
  /^[A-Za-z0-9]{1}((?!.*\.\.)[A-Za-z0-9!#$%&'*+\-\/=?^_`{|\.]{0,62})[A-Za-z0-9]{1}\@(?=.{4,253}$)([A-Za-z0-9]{2,}(\.[A-Za-z0-9]{2,}){1,})$/

export const includeOnlyCorrectSymbols = (string) => {
  return validator(!correctSymbolsRegExp.test(string), 'invalid-format-text')
}

export const required = (value, allValues, formProps, name) => {
  const { registeredFields } = formProps
  const fieldProps = registeredFields && registeredFields[name]

  if (fieldProps && fieldProps.count === 0) return undefined

  return validator(isNil(value) || value === '', 'common-fields.required-error')
}

export const requiredNumber = (value) => validator(isNaN(value), 'common-fields.number-error')
export const requiredWithoutPoints = (value) => validator(/\./.test(value), 'common-fields.integer-number-error')
export const email = (value) => {
  // return validator(!/^([a-z0-9_.-]+)@([a-z0-9_.-]+)\.([a-z]+)$/i.test(value), 'common-fields.email-correct-error')
  return validator(!emailRegExp.test(value), 'common-fields.email-correct-error')
}
export const localIDValidator = (value) =>
  validator(!/^[0-9]{11}$/i.test(value), 'common-fields.local-id-correct-error')

export const getIsIncorrectNumberLength = (value = '') => value.length < 7 || value.length > 15
export const isZeroAt = (index, value = '') => value[index] === '0'

export const deleteZerosFromStart = (value = '') => {
  let numOfZerosToDelete = 0
  for (let char of value) {
    if (char === '0') {
      numOfZerosToDelete += 1
      continue
    }
    break
  }
  return value.slice(numOfZerosToDelete)
}

export const phoneCode = (value = '') => {
  const isFirstZero = isZeroAt(0, value)
  const isIncorrectCode = !/^([0-9]{1,4})$/.test(value)
  return validator(isIncorrectCode || isFirstZero, 'common-fields.number-error')
}

export const phoneNumber = (value = '') => {
  const valueWithoutMeaninglessZeros = deleteZerosFromStart(value)
  const isIncorrectNumberLength = getIsIncorrectNumberLength(valueWithoutMeaninglessZeros)
  return validator(isIncorrectNumberLength, 'invalid-format-text')
}

export const login = (value) => {
  const isInvalidPhoneNumber = !phoneNumberRegExp.test(value)
  const isInvalidEmail = !emailRegExp.test(value)
  return validator(isInvalidPhoneNumber && isInvalidEmail, 'invalid-format-text')
}

const fieldCheckGenerator = curry(
  (checkFn, errorMessageId, fieldToCompare) => (value, allValues) =>
    validator(checkFn(value, allValues[fieldToCompare]), errorMessageId)
)

export const matchField = fieldCheckGenerator(complement(equals), 'common-fields.passwords-should-match-error')
export const dontMatchField = fieldCheckGenerator(equals, 'common-fields.passwords-should-not-match-error')

export const validateMaxSize = curry((maxSize, file) =>
  validator(pathOr(0, ['meta', 'size'], file) > maxSize, 'common-fields.max-file-size-error')
)

export const plateNumberRegexp = /[\d|\w]{2,8}/i

let licencePlaceNumberPattern = /[^A-Za-z0-9-]/
if (isBluewave) {
  // todo: this pattern might be in the theme json
  licencePlaceNumberPattern = /[^,A-Za-z0-9-]/
}
export const onlyAlphaNumericDash = (value) => (value ? value.replace(licencePlaceNumberPattern, '') : value)
