import * as Actions from '../consts'
import { normalizeData } from '../../utils'
import { reservationsSchema } from '../schemas'
import { LOGOUT_SUCCESS, SIGNOUT_SUCCESS } from '../../auth/consts'

const initialState = {
  loading: false,
}

const reservations = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case Actions.FETCH_ALL_USER_RESERVATIONS:
      return {
        ...state,
        loading: true,
      }
    case Actions.FETCH_ALL_USER_RESERVATIONS_SUCCESS:
      return {
        ...normalizeData('reservations', reservationsSchema)(payload),
        loading: false,
      }
    case SIGNOUT_SUCCESS:
    case LOGOUT_SUCCESS:
    case Actions.RESET_USER:
      return initialState
    case Actions.FETCH_ALL_USER_RESERVATIONS_FAIL:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default reservations
