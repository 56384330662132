import { call, put, takeEvery, select } from 'redux-saga/effects'
import { parse } from 'qs'
import { prop, cond, equals } from 'ramda'

import {
  preparePaymentRequester,
  paymentTransactionRequester,
  cancelTransactionRequester,
  sendCreditCardSuccess,
} from './api/pay'
import { prepareForPayment, sendTransactionStatus, paymentReplaceLocation, creditCardPaymentComplete } from './actions'
import {
  PAYMENT_PAYING,
  PAYMENT_PAYED,
  PAYMENT_PAY_FAILED,
  SEND_TRANSACTION_STATUS_FAILED,
  SEND_TRANSACTION_STATUS_SUCCESS,
  IMMEDIATELY_PAYMENT_PAYED,
} from './consts/payment'

export const request = cond([
  [equals('COMPLETED'), () => paymentTransactionRequester],
  [equals('CANCELLED'), () => cancelTransactionRequester],
])

function* payProcess({ payload: { reservationToken } }) {
  const { status } = yield select(prop('payment'))

  if (status === 'payed') {
    yield put({
      type: PAYMENT_PAYED,
      payload: status === 'payed',
    })

    return
  }

  const { error } = yield call(preparePaymentRequester, { reservationToken, paymentMethod: 'CREDIT' })

  if (error) {
    yield put({
      type: PAYMENT_PAY_FAILED,
      payload: error,
    })
  } else {
    yield put({
      type: IMMEDIATELY_PAYMENT_PAYED,
      payload: reservationToken,
    })
  }
}

function* sendCCSuccess({ payload: { reservationId, ...data } }) {
  const locale = yield select(prop('locale'))

  const { response, error } = yield call(sendCreditCardSuccess, {
    reservationId,
    locale,
    ...data,
  })

  if (response) {
    const { ticketsUrl } = response
    yield put(paymentReplaceLocation(ticketsUrl))
  } else if (error) {
    yield put({
      type: PAYMENT_PAY_FAILED,
      payload: error,
    })
  }
}

function* preparePaymentSaga({
  payload: { reservationToken, paymentMethod, creditCardPayment = false, ccDialogData = {} },
}) {
  const { status } = yield select(prop('payment'))

  if (status === 'payed') {
    yield put({
      type: PAYMENT_PAYED,
      payload: status === 'payed',
    })

    return
  }

  const { response: data, error } = yield call(preparePaymentRequester, {
    reservationToken,
    paymentMethod,
    creditCard: creditCardPayment,
  })

  if (data) {
    if (paymentMethod === 'APPLEPAY') {
      console.log('Data coming from /prepare: ', data)
      console.log('window.applePay.paymentPayload = ', window.applePay.paymentPayload)

      if (
        typeof window.applePay !== 'undefined' &&
        typeof window.applePay.BeginApplePayPayment === 'function' &&
        window.applePay.paymentPayload
      ) {
        window.applePay.BeginApplePayPayment(window.applePay.paymentPayload)
        window.applePay.paymentPayload = null
      }

      return
    }

    if (!creditCardPayment) {
      const { redirectUrl } = data
      yield put(paymentReplaceLocation(redirectUrl))
    } else {
      const { transactionUuid = '', key = '' } = data

      try {
        window.Maksekeskus.Checkout.initialize({
          ...ccDialogData,
          transaction: transactionUuid,
          key,
        })

        window.Maksekeskus.Checkout.open()
      } catch (e) {
        yield put({
          type: PAYMENT_PAY_FAILED,
          payload: e,
        })
      }
    }
  } else if (error) {
    yield put({
      type: PAYMENT_PAY_FAILED,
      payload: error,
    })
  }
}

export function* sendPaymentTransaction({ payload: { paymentReservation, urlSearchParams } = {} }) {
  try {
    if (urlSearchParams) {
      const searchParams = parse(urlSearchParams.slice(1))
      const { status } = JSON.parse(searchParams.json)

      const paymentRequester = request(status)

      if (paymentRequester) {
        const { error } = yield call(paymentRequester, searchParams)

        if (!error) {
          yield put({
            type: SEND_TRANSACTION_STATUS_SUCCESS,
            payload: { paymentReservation: { ...paymentReservation, status } },
          })
        } else {
          throw new Error(error)
        }
      } else {
        throw new Error(`Transaction status ${status} not recognized`)
      }
    }
  } catch (error) {
    yield put({
      type: SEND_TRANSACTION_STATUS_FAILED,
      payload: error,
    })
  }
}

function* paying() {
  yield takeEvery(PAYMENT_PAYING, payProcess)
}

function* preparePayment() {
  yield takeEvery(prepareForPayment, preparePaymentSaga)
}

function* captureCreditCardSuccess() {
  yield takeEvery(creditCardPaymentComplete, sendCCSuccess)
}

function* watchSendingTransactionStatus() {
  yield takeEvery(sendTransactionStatus, sendPaymentTransaction)
}

export function* paymentSaga() {
  yield [paying(), preparePayment(), watchSendingTransactionStatus(), captureCreditCardSuccess()]
}
